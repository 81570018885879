<template>
  <section class="head">
    <div class="content">
      <div class="top">
        <div class="left">
          <h1>{{ projectData.taskName }}&nbsp;</h1>
          <p>
            {{ $t("subject.learning_time") }}：
            <!-- 学习时间： -->
            {{ dateFormat(projectData.startTime) }} ~
            {{ dateFormat(projectData.endTime) }}
          </p>
        </div>
        <div class="right" v-if="projectData.taskId">
          <i>{{ projectData.signupUser }}</i>
          /
          {{
            projectData.signupUsers <= 0
              ? $t("signup.unlimited")
              : $t("signup.people", [projectData.signupUsers])
          }}
          <!-- 无限制 : 人 -->
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <p>
            <span>{{ $t("signup.sign_time") }}</span
            >{{ dateFormat(projectData.signupStart) }} ~
            {{ dateFormat(projectData.signupEnd) }}
            <!-- 报名（启）止时间： -->
          </p>
          <!-- <p>
            <span>{{ $t("signup.sign_scope") }}：</span>
            报名范围：
            <template v-if="projectData.signupScope == 8">{{
              $t("signup.company_wide")
            }}</template>
            全公司
            <template v-else-if="projectData.signupScope == 6">
              <i v-for="(item, index) in projectData.signupScopes" :key="index">
                {{ item.name
                }}<template v-if="item.inclSub == 1"
                  >（{{ $t("signup.contains_subdepartments") }}）</template
                >
                （包含子部门）
              </i>
            </template>
          </p> -->
          <p>
            <span>{{ $t("signup.do_need_review") }}：</span>
            <!-- 是否需要审核： -->
            {{
              projectData.signupAudit == 1
                ? $t("signup.need")
                : $t("signup.no_need")
            }}
            <!-- "需要" : "不需要"  -->
          </p>
        </div>
        <div class="right">
          <span v-if="currentTime < projectData.signupStart">{{
            $t("signup.registration_not_started")
          }}</span>
          <!-- 报名未开始 -->
          <span v-if="projectData.signupStatus == 6">{{
            $t("Pub_Lab_PendingAudit")
          }}</span>
          <!-- 待审核 -->
        </div>
      </div>
      <section class="reject" v-if="projectData.signupStatus == 7">
        <p>
          <span>{{ $t("signup.reason_for_ejection") }}：</span
          >{{ projectData.signupReason || "无" }}
        </p>
        <!-- 驳回原因： -->
      </section>
      <div class="btns">
        <template
          v-if="projectData.signupStatus == 1 || projectData.signupStatus == 6"
        >
          <div
            class="btn theme"
            v-if="projectData.signupStatus == 1"
            @click="toStudy"
          >
            {{ $t("LB_Cert_BeginLearn") }}
            <!-- 开始学习 -->
          </div>
          <div
            class="btn"
            v-if="
              projectData.signupStatus == 1 || projectData.signupStatus == 6
            "
            @click="toCancel"
          >
            {{ $t("signup.cancel_sign_up") }}
            <!-- 取消报名 -->
          </div>
        </template>
        <template
          v-else-if="
            currentTime >= projectData.signupStart &&
            currentTime <= projectData.signupEnd
          "
        >
          <div
            class="btn"
            v-if="
              projectData.signupStatus == 7 || projectData.signupStatus == 9
            "
            @click="toSignup"
          >
            {{ $t("signup.afresh_sign_up") }}
            <!-- 重新报名 -->
          </div>
          <div
            class="btn"
            v-if="
              projectData.signupStatus == 2 || projectData.signupStatus == 0
            "
            @click="toSignup"
          >
            {{ $t("signup.start_sign_up") }}
            <!-- 开始报名 -->
          </div>
        </template>
        <template v-else>
          <div
            class="btn gray"
            v-if="
              projectData.signupStatus == 7 || projectData.signupStatus == 9
            "
          >
            {{ $t("signup.afresh_sign_up") }}
            <!-- 重新报名 -->
          </div>
          <div class="btn gray" v-else>{{ $t("signup.start_sign_up") }}</div>
          <!-- 开始报名 -->
        </template>
      </div>
    </div>
  </section>

  <a-spin :spinning="loadding">
    <section class="mian">
      <div class="detail">
        <a-tabs v-model:activeKey="activeKey">
          <!-- 详情介绍 -->
          <a-tab-pane key="1" :tab="$t('project.details')">
            <div style="padding: 14px 30px 10px 30px">
              <div
                v-if="projectData.intro"
                class="v-html"
                v-html="projectData.intro"
              ></div>
              <div style="padding: 40px 0" v-else>
                <a-empty :description="$t('LB_NoIntroduction')" />
                <!-- 暂无介绍 -->
              </div>
            </div>
          </a-tab-pane>
          <!-- 培训目录 -->
          <a-tab-pane key="2" :tab="$t('project.catalog')">
            <div class="directory" v-if="stagesData.length">
              <div
                v-for="(stage, index) in stagesData"
                :key="stage.stageId"
                :id="'stage' + (index + 1)"
              >
                <div class="title">
                  <div class="headline">
                    <h5>
                      {{ stagesData[index].stageName }}
                    </h5>
                  </div>
                  <div class="intro" v-if="stagesData[index].intro">
                    {{ stagesData[index].intro }}
                  </div>
                </div>
                <ul class="list">
                  <li
                    v-for="item in stagesData[index].details"
                    :key="item.detailId"
                  >
                    <div class="item-box">
                      <div class="unfold" v-if="item.resourceType == 8">
                        <MinusSquareOutlined
                          style="font-size: 15px; padding-top: 2px"
                        />
                      </div>
                      <div class="tag-box">
                        <span
                          class="tag float"
                          v-if="
                            item.resourceType == 4 ||
                            item.resourceType == 5 ||
                            item.resourceType == 7
                          "
                        >
                          {{ resourceType[item.resourceType - 1].name }}
                        </span>
                        <span class="tag float" v-else>
                          {{ resourceType[item.resourceType - 1].name }}
                        </span>
                      </div>
                      <div class="info">
                        <h5 v-if="item.resourceType != 8">
                          {{ item.detailName }}
                        </h5>
                        <h5 v-else>
                          <span class="names">
                            <template
                              v-for="(local, localIndex) in item.localList"
                              :key="localIndex"
                            >
                              {{ local.detailName }}
                              <template
                                v-if="localIndex != item.localList.length - 1"
                                >，</template
                              >
                            </template>
                          </span>
                          <span class="total"
                            >（{{
                              $t("project.total_of", [item.localList.length])
                            }}）</span
                          >
                          <!-- 共 场 -->
                        </h5>
                        <div class="bottom">
                          <template v-if="item.resourceType == 3">
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                item.exam.examTime[0].substring(0, 16)
                              }}
                              -
                              {{ item.exam.examTime[1].substring(0, 16) }}
                            </span>
                          </template>
                          <template v-else-if="item.resourceType == 8">
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                item.timeList[0].list[0].startTime.substring(
                                  0,
                                  16
                                )
                              }}
                              -
                              {{
                                item.timeList[item.timeList.length - 1].list[
                                  item.timeList[item.timeList.length - 1].list
                                    .length - 1
                                ].endTime.substring(0, 16)
                              }}
                            </span>
                          </template>
                          <template v-else-if="item.resourceType == 9">
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                dateFormat(item.live.startTime)
                              }}
                              -
                              {{ dateFormat(item.live.endTime) }}
                            </span>
                          </template>
                          <template v-else>
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                dateFormat(projectData.startTime)
                              }}
                              -
                              {{ dateFormat(projectData.endTime) }}
                            </span>
                          </template>
                        </div>
                        <div class="bottom">
                          <span v-if="item.resourceType == 3">
                            {{ $t("project.require") }}：{{
                              $t(
                                item.exam && item.exam.examPass == 2
                                  ? "project.passing_the_exam"
                                  : "ExamsAreCompletedWhenYouHandInYourPapers"
                              )
                            }}
                            <!-- 要求：考试成绩及格后才可过关 -->
                          </span>
                        </div>
                      </div>
                      <div class="status"></div>
                    </div>
                    <div class="local-box" v-if="item.resourceType == 8">
                      <div
                        class="day-item"
                        v-for="(day, dayIndex) in item.timeList"
                        :key="dayIndex"
                      >
                        <h3 class="day">{{ day.date }}</h3>
                        <div
                          class="hour-item"
                          :class="{ ed: hour.isEd }"
                          v-for="(hour, hourIndex) in day.list"
                          :key="hourIndex"
                        >
                          <div class="hour-times">
                            <div class="hour-time">
                              {{ hour.startTime.slice(11, 16) }} -
                              {{ hour.endTime.slice(11, 16) }}
                            </div>
                            <div class="hour-duration">
                              {{
                                hour.duration.h
                                  ? hour.duration.h + $t("CM_Hour")
                                  : ""
                              }}{{
                                hour.duration.m
                                  ? hour.duration.m + $t("XB_Min")
                                  : ""
                              }}
                            </div>
                          </div>
                          <div class="hour-title">
                            <span class="hour-tag">{{ hour.typeName }}</span>
                            <p v-if="hour.type == 2">
                              <a-tooltip>
                                <template #title>{{
                                  hour.data.detailName
                                }}</template>
                                {{ hour.data.detailName }}
                              </a-tooltip>
                            </p>
                            <p v-else>{{ $t("train.attendance") }}</p>
                            <!-- 考勤 -->
                          </div>
                          <div class="hour-info">
                            <p v-if="hour.type == 2">
                              <EnvironmentOutlined />
                              <span class="span">
                                {{ hour.data.train.address }}
                              </span>
                              <span class="span">
                                <!-- 讲师： -->
                                {{ $t("XB_Course_Lecturer") }}：<OpenData
                                  type="userName"
                                  :openid="hour.data.train.lecturerName"
                                  v-if="hour.data.train.lecturerType == 1"
                                /><span v-else>{{
                                  hour.data.train.lecturerName
                                }}</span>
                              </span>
                            </p>
                            <p v-else>
                              <!-- 考勤负责人： -->
                              {{ $t("train.head") }}：<OpenData
                                type="userName"
                                :openid="hour.data.train.leaderUser"
                              />
                            </p>
                          </div>
                          <div class="hour-status"></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </section>
  </a-spin>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, getCurrentInstance, createVNode } from "vue";
import { useRouter, useRoute } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { resourceType } from "@/utils/business";
import { projectDetail, signupCancel, signup } from "@/api/project";
import OpenData from "@/components/OpenData.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const taskId = parseInt(route.query.id || 0);

    const currentTime = Date.parse(new Date()) / 1000;

    let loadding = ref(true),
      activeKey = ref("2"),
      projectData = ref({}),
      stagesData = ref([]),
      activeStage = ref(0),
      resourceTypes = ref([]);

    const getDetail = () => {
      projectDetail(taskId, { isSignUp: 1 }).then((res) => {
        loadding.value = false;
        if (res.ret === 0) {
          let d = res.data,
            nowTime = new Date().getTime();
          if (d.signupStatus == 1 && d.signupIsview == 2) {
            router.replace({
              path: "/project/detail",
              query: {
                id: taskId,
              },
            });
            return false;
          }
          d.remainderDay = Math.floor((d.endTime * 1000 - nowTime) / 86400000);
          d.remainderHours = Math.floor((d.endTime * 1000 - nowTime) / 3600000);
          d.collaboratorArr = JSON.parse(d.collaborator);
          resourceTypes.value = JSON.parse(JSON.stringify(resourceType));
          if (d.stages.length && d.stages[0].details)
            d.stages[0].checked = true;
          for (let i = 0; i < d.stages.length; i++) {
            let firstLocal = null;
            d.stages[i].details = d.stages[i].details || [];
            for (var j = 0; j < d.stages[i].details.length; j++) {
              resourceTypes.value[
                d.stages[i].details[j].resourceType - 1
              ].extraArr.push(d.stages[i].details[j]);
              if (d.stages[i].details[j].resourceType == 8) {
                if (firstLocal == null) {
                  firstLocal = j;
                  d.stages[i].details[firstLocal].localList = [];
                }
                d.stages[i].details[firstLocal].localList.push(
                  JSON.parse(JSON.stringify(d.stages[i].details[j]))
                );
              }
            }
            if (firstLocal != null) {
              let details = d.stages[i].details;
              for (let index = 0; index < details.length; index++) {
                const detail = details[index];
                if (detail.resourceType == 8 && index != firstLocal) {
                  details.splice(index, 1);
                  index--;
                }
              }
              let times = [];
              let now = new Date();
              details[firstLocal].timeList = [];
              details[firstLocal].localList.forEach((item) => {
                if (
                  item.train.inStartTime != "" &&
                  item.train.inEndTime != "" &&
                  item.train.inStartTime != "1970-01-01 08:00:00" &&
                  item.train.inEndTime != "1970-01-01 08:00:00"
                ) {
                  times.push({
                    data: item,
                    startTime: item.train.inStartTime,
                    endTime: item.train.inEndTime,
                    duration: getDuration(
                      item.train.inEndTime,
                      item.train.inStartTime
                    ),
                    type: 1,
                    typeName: $t("Lab_LessonDetail_T_SignIn"),
                    // 签到
                    isEd: new Date(item.train.inStartTime) <= now,
                  });
                }
                let s = dateFormat(item.train.startTime, "YYYY-MM-DD HH:mm:ss"),
                  e = dateFormat(item.train.endTime, "YYYY-MM-DD HH:mm:ss");
                times.push({
                  data: item,
                  startTime: s,
                  endTime: e,
                  duration: getDuration(e, s),
                  type: 2,
                  typeName: $t("Pub_Tab_CT"),
                  // 面授
                  isEd: new Date(s) <= now,
                });
                if (
                  item.train.outStartTime != "" &&
                  item.train.outEndTime != "" &&
                  item.train.outStartTime != "1970-01-01 08:00:00" &&
                  item.train.outEndTime != "1970-01-01 08:00:00"
                ) {
                  times.push({
                    data: item,
                    startTime: item.train.outStartTime,
                    endTime: item.train.outEndTime,
                    duration: getDuration(
                      item.train.outEndTime,
                      item.train.outStartTime
                    ),
                    type: 3,
                    typeName: $t("Lab_LessonDetail_T_SignOut"),
                    // 签退
                    isEd: new Date(item.train.outStartTime) <= now,
                  });
                }
              });
              times.sort((a, b) => (b.startTime < a.startTime ? 1 : -1));
              let date = "",
                list = [];
              times.forEach((item, index) => {
                let day = item.startTime.slice(0, 10);
                if (index == 0) {
                  date = day;
                }
                if (day == date) {
                  list.push(item);
                  if (index == times.length - 1) {
                    if (list.length) {
                      details[firstLocal].timeList.push({
                        date,
                        list,
                      });
                    }
                  }
                } else {
                  if (list.length) {
                    details[firstLocal].timeList.push({
                      date,
                      list,
                    });
                  }
                  date = day;
                  list = [item];
                }
              });
            }
          }
          projectData.value = d;
          stagesData.value = d.stages || [];
        } else {
          router.replace({
            path: `/error`,
            query: {
              title: res.msg,
              path: "/signup",
              name: $t("cm_sign_up"),
              // 报名中心
            },
          });
        }
      });
    };
    getDetail();

    const toStudy = () => {
      router.push({
        path: "/project/detail",
        query: {
          id: taskId,
        },
      });
    };

    const toSignup = () => {
      Modal.confirm({
        title: $t("system_prompt"),
        // 系统提示
        icon: createVNode(ExclamationCircleOutlined),
        content: $t("signup.sure_sign_up"),
        // 确定报名吗？
        okText: $t("CM_Affirm"),
        // 确认
        onOk() {
          signup({ id: taskId }).then((res) => {
            if (res.ret == 0) {
              if (res.data == "SignupHaveScope") {
                proxy.$message.success(
                  $t("ExistingProjectPermissionsNoRegistrationRequired")
                );
                // 已有项目的学习权限，无需参与报名！
                toStudy();
              } else {
                proxy.$message.success($t("XB_SignUpSuccessfully"));
                // 报名成功！
                getDetail();
              }
            }
          });
        },
        cancelText: $t("CM_Cancel"),
        // 取消
      });
    };

    const toCancel = () => {
      Modal.confirm({
        title: $t("system_prompt"),
        // 系统提示
        icon: createVNode(ExclamationCircleOutlined),
        content: $t("signup.sure_cancel_sign_up"),
        // 确定取消报名吗？
        okText: $t("CM_Affirm"),
        // 确认
        onOk() {
          proxy.$message.success($t("signup.cancel_sign_up_success"));
          // 取消成功！
          signupCancel({ id: taskId }).then((res) => {
            getDetail();
          });
        },
        cancelText: $t("CM_Cancel"),
        // 取消
      });
    };

    const getDuration = (date1, date2) => {
      let timestamp = 0;
      timestamp = (new Date(date1) - new Date(date2)) / 1000;
      let timesJson = timeTransition(timestamp);
      return {
        h: timesJson.h,
        m: timesJson.m,
      };
    };

    // 时长转换
    const timeTransition = (val) => {
      if (val < 60) {
        return {
          h: 0,
          m: 0,
          s: val,
        };
      } else {
        let m = Math.floor(val / 60); // 分钟
        let s = Math.floor(val % 60); // 余秒
        if (m < 60) {
          return {
            h: 0,
            m: m,
            s: s,
          };
        } else {
          let h = Math.floor(m / 60); // 小时数
          let m2 = Math.floor(m % 60); // 余分钟
          return {
            h: h,
            m: m2,
            s: s,
          };
        }
      }
    };

    return {
      dateFormat,
      resourceType,
      currentTime,
      loadding,
      projectData,
      stagesData,
      activeStage,
      resourceTypes,
      activeKey,
      toStudy,
      toSignup,
      toCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  padding-top: 32px;
  .content {
    .mixinWrap();
    background-color: #fff;
    border-radius: 6px;
    padding: 36px;
    .top {
      .mixinFlex(space-between; center);
      .left {
        width: calc(100% - 200px);
        h1 {
          font-size: 36px;
          font-weight: 500;
          color: #202020;
          line-height: 54px;
          margin: 0;
        }
        p {
          font-size: 20px;
          color: #999999;
          line-height: 30px;
          margin: 6px 0 0 0;
        }
      }
      .right {
        font-size: 20px;
        padding-bottom: 20px;
        i {
          font-style: normal;
          color: #f79045;
        }
      }
    }
    .bottom {
      margin-top: 20px;
      background-color: #f5f5f6;
      border-radius: 6px;
      padding: 14px 24px;
      .mixinFlex(space-between; center);
      .left {
        p {
          margin: 0;
          padding: 5px 0;
          font-size: 14px;
          color: #666;
          span {
            font-size: 16px;
            color: #333;
          }
          i {
            font-style: normal;
            margin-right: 10px;
            display: inline-block;
          }
        }
      }
      .right {
        color: #d32f2f;
        font-size: 20px;
        padding-left: 20px;
      }
    }
    .reject {
      margin-top: 20px;
      background-color: #f5f5f6;
      border-radius: 6px;
      padding: 14px 24px;
      p {
        margin: 0;
        padding: 5px 0;
        font-size: 14px;
        color: #666;
        span {
          font-size: 16px;
          color: #d32f2f;
        }
      }
    }
    .btns {
      margin-top: 36px;
      .mixinFlex(center; center);
      .btn {
        width: 200px;
        height: 54px;
        text-align: center;
        line-height: 52px;
        border: 1px solid @color-theme;
        border-radius: 4px;
        font-size: 20px;
        color: @color-theme;
        margin: 0 10px;
        cursor: pointer;
        &.gray {
          background-color: #999999;
          color: #fff;
          border-color: #999999;
          cursor: not-allowed;
        }
        &.theme {
          background-color: @color-theme;
          color: #fff;
        }
      }
    }
  }
}

.mian {
  padding-bottom: 64px;
  .mixinWrap();
  .detail {
    background-color: #fff;
    border-radius: 6px;
    margin-top: 24px;
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
    .directory {
      padding: 14px 30px 10px 30px;
      .title {
        background-color: #f5f5f6;
        padding: 20px 24px;
        .headline {
          .mixinFlex(space-between; center);
          h5 {
            font-size: 16px;
            margin: 0;
            line-height: 1.5;
            width: calc(100% - 50px);
            span {
              padding-right: 16px;
            }
          }
        }
        .intro {
          margin-top: 6px;
          font-size: 14px;
        }
      }
      .list {
        li {
          border-bottom: 1px solid #eaf0ee;
          .item-box {
            .mixinFlex(space-between);
            padding: 22px 24px 22px 38px;
            position: relative;
            .unfold {
              position: absolute;
              left: 15px;
            }
            .tag-box {
              width: 40px;
              position: relative;
              .tag {
                width: max-content;
                height: 22px;
                padding: 0 4px;
                border: 1px solid @color-theme;
                color: @color-theme;
                font-size: 14px;
                border-radius: 2px;
                .mixinFlex(center; center);
                &.float {
                  position: absolute;
                  right: 0;
                  top: 0;
                }
              }
            }
            .info {
              width: calc(100% - 150px);
              h5 {
                font-size: 16px;
                line-height: 22px;
                .names {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-all;
                  display: inline-block;
                  max-width: 90%;
                }
                .total {
                  color: @color-theme;
                  vertical-align: top;
                }
              }
              .bottom {
                color: #666;
                font-size: 14px;
              }
            }
            .status {
              font-size: 14px;
              width: 80px;
              text-align: right;
              img {
                width: 15px;
                height: 13px;
                vertical-align: -1px;
              }
            }
          }
          .local-box {
            background-color: #f5f5f6;
            padding: 24px;
            margin-bottom: 22px;
            .day-item {
              margin-bottom: 4px;
              .day {
                font-size: 16px;
                margin-bottom: 16px;
              }
              .hour-item {
                padding-bottom: 16px;
                .mixinFlex(space-between);
                position: relative;
                &::before {
                  content: "";
                  width: 11px;
                  height: 11px;
                  border-radius: 50%;
                  background-color: #999;
                  border: 2px solid #f5f5f6;
                  position: absolute;
                  top: 6px;
                  left: 115px;
                  z-index: 2;
                }
                &.ed {
                  &::before {
                    content: "";
                    width: 13px;
                    height: 13px;
                    background-color: #fff;
                    border: 4px solid @color-theme;
                    top: 5px;
                    left: 114px;
                    box-shadow: 0 0 2px 1px @color-theme;
                  }
                }
                &::after {
                  content: "";
                  width: 1px;
                  background-color: #999;
                  position: absolute;
                  top: 0;
                  left: 120px;
                  bottom: 0;
                  z-index: 1;
                }
                &:nth-child(2) {
                  &::after {
                    top: 6px;
                  }
                }
                &:last-child {
                  &::after {
                    bottom: 46px;
                  }
                }
                .hour-times {
                  .hour-time {
                    font-size: 14px;
                    margin-bottom: 5px;
                  }
                  .hour-duration {
                    font-size: 12px;
                    color: #999;
                    text-align: right;
                  }
                }
                .hour-title {
                  width: calc(100% - 640px);
                  margin-left: 60px;
                  .mixinFlex();
                  .hour-tag {
                    width: 40px;
                    height: 22px;
                    border: 1px solid @color-theme;
                    color: @color-theme;
                    font-size: 14px;
                    border-radius: 2px;
                    .mixinFlex(center; center);
                  }
                  p {
                    width: calc(100% - 40px);
                    margin: 0;
                    padding-left: 14px;
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                  }
                }
                .hour-info {
                  width: 400px;
                  color: #666;
                  .mixinFlex();
                  p {
                    margin: 0;
                    padding-left: 14px;
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    .span {
                      padding-right: 14px;
                    }
                  }
                }
                .hour-status {
                  width: 80px;
                  text-align: right;
                  color: #666;
                }
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
